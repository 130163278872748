// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { catalogoScripts } from "../../../scripts/catalogo"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Relación facturas</h2>
                <p>
                Al emitir notas de crédito o cualquier operación que asocie dos o más facturas, hay que declarar la clave de la relación entre ellas. 
                </p>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/sat/relacionFacturas/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>clave: </b> La clave de la relación
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>descripcion: </b> La descripción de la relación.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/catalogo/catalogo-paises`} previous={`/api/catalogo/catalogo-impuestos`}/>
            </div>

            <div className="side">
                <CodeBlock title="Catálogo Impuestos" requests={[]} response={catalogoScripts.relacionFacturas.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
